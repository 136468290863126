export const getCookie = (name: string) => {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
  else return undefined;
};

export const setCookie = (name: string, value: string, path: string = "/") => {
  document.cookie = `${name}=${value}; path=${path}`;
};

export const deleteCookie = (name: string, path: string = "/") => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${location.hostname}; path=${path}`;
};

export const getOrSetCookie = (name: string, value: string) => {
  const existingCookie = getCookie(name);

  if (existingCookie) return existingCookie;

  setCookie(name, value);

  return value;
};
