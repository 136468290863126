export const getPathFromURL = (url: string) => {
  if (url.startsWith("/")) {
    return url;
  } else if (url.startsWith("http")) {
    const urlObject = new URL(url);
    return `${urlObject.pathname}${urlObject.search}`;
  } else {
    // Assuming here that the url could be google.com/path, so I'm prepending http://
    const formattedUrl = "http://" + url;
    const urlObject = new URL(formattedUrl);
    return `${urlObject.pathname}${urlObject.search}`;
  }
};
