import React, {
  useEffect,
  useContext,
  createRef,
  FC,
  useCallback,
} from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { ActionButton, ActionLink, useDeviceTypes } from "halifax";
import {
  B2B_PORTAL_AUTH_REDIRECT_TO,
  SessionInfo,
  RewardsAccount,
  VIEWED_ONBOARDING,
  SKIPPED_ONBOARDING,
  COMPLETED_ONBOARDING,
  LAUNCHED_APPLICATION,
  LaunchedApplicationProperties,
  WalletDetailsResponse,
  TravelWalletCredit,
  StatementCreditDetail,
  CreditDetail,
} from "redmond";
import clsx from "clsx";

import { isCorpTenant } from "@capone/common";
import { getPathFromURL } from "@capone/common/src/utils/getPathFromURL";

import "./styles.scss";
import { ClientContext } from "../../App";
import { PATH_B2B_HOME, PATH_FLIGHTS_HOME } from "../../utils/paths";
import { fetchUserInfo } from "../../api/v1/user/fetchUserInfo";
import { fetchRewardsAccounts } from "../../api/v1/rewards/fetchRewardsAccounts";
import { LoadingScreen } from "../LoadingScreen";
import * as constants from "./constants";
import { trackEvent } from "../../api/v1/analytics/trackEvent";
import ExperimentsProvider, {
  addTrackingProperties,
  AVAILABLE,
  FTUX_REMOVAL,
  getExperimentVariant,
  useExperiments,
} from "../../context/experiments";
import BulletOptions from "./BulletOptions";
import { fetchTravelWalletDetails } from "../../api/v1/user/fetchTravelWalletDetails";
import { config } from "../../api/config";

interface IFirstTimeUserPageProps extends RouteComponentProps {
  language: string;
}

export enum FtuxStep {
  Step0,
  Step1,
  Step2,
  Step3,
}

const fetchUser = async (): Promise<SessionInfo | undefined> => {
  try {
    return await fetchUserInfo();
  } catch (err) {
    console.log(err);
  }
  return;
};

const getLargestRewardsAccount = (
  rewardsAccounts: RewardsAccount[]
): RewardsAccount => {
  const cardsToShowWithPriorityOnRewardsBanner = [
    "Venture X",
    "Venture",
    "VentureOne",
    "Spark Classic Miles",
    "Spark Miles",
    "Spark Miles Select",
  ].map((card) => card.toLowerCase());
  const earnMultiplier = (account: RewardsAccount) =>
    account.earn.carsMultiplier;
  return rewardsAccounts.sort((prev, current) => {
    const cardsToNotShowBannerOn = ["Walmart"].map((card) =>
      card.toLowerCase()
    );

    if (
      cardsToNotShowBannerOn.includes(current.productDisplayName.toLowerCase())
    )
      return -1;

    if (earnMultiplier(current) === earnMultiplier(prev)) {
      return cardsToShowWithPriorityOnRewardsBanner.includes(
        current.productDisplayName.toLowerCase()
      )
        ? 1
        : -1;
    }
    return earnMultiplier(current) - earnMultiplier(prev);
  })[0];
};

const fetchRewards = async (): Promise<RewardsAccount[] | undefined> => {
  try {
    // TODO: rewards should probably be a common module that can be shared in other modules (core change);
    // once we get to that, this part shall be refactored.
    return await fetchRewardsAccounts();
  } catch (err) {
    console.log(err);
    return;
  }
};

const fetchWalletDetails = async (): Promise<
  WalletDetailsResponse | undefined
> => {
  try {
    return await fetchTravelWalletDetails();
  } catch (err) {
    return;
  }
};

const trackLaunchedApp = (
  rewardsAccounts: RewardsAccount[],
  creditBreakdown: CreditDetail[],
  credit?: TravelWalletCredit,
  trackingProperties?: Map<string, string>
) => {
  let properties: LaunchedApplicationProperties = {
    landing_screen: "ftux",
    url: window.location.pathname,
    rewards_accounts: rewardsAccounts
      .map((r) => r.productDisplayName)
      .join(","),
    has_credits: !!credit?.amount?.amount,
    credit_balance: credit?.amount ? Math.abs(credit.amount.amount) : 0,
    vx_statement_credit_balance:
      creditBreakdown
        ?.filter((b) => b.CreditDetail === "Statement")
        .reduce(
          (prev, curr) =>
            prev + (curr as StatementCreditDetail).usableAmount.amount,
          0
        ) || 0,
  };

  if (trackingProperties) {
    properties = addTrackingProperties(
      trackingProperties,
      properties
    ) as LaunchedApplicationProperties;
  }

  trackEvent({
    eventName: LAUNCHED_APPLICATION,
    properties,
  });
};

const FirstTimeUserContent: FC<{
  props: IFirstTimeUserPageProps;
  sessionInfo?: SessionInfo;
}> = ({ props, sessionInfo }) => {
  const { language, history } = props;
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);

  const expState = useExperiments();
  const FTUXRemovalVariant = getExperimentVariant(
    expState.experiments,
    FTUX_REMOVAL
  );

  const redirectToSessionStorageUrl = (url: string) => {
    if (isCorpTenant(config.TENANT)) {
      // Need to `location.replace` because we potentially change React app
      window.location.replace(getPathFromURL(url));
    } else {
      history.push(getPathFromURL(url));
    }

    sessionStorage.removeItem(B2B_PORTAL_AUTH_REDIRECT_TO);
  };

  const handleRedirect = () => {
    const redirectTo = sessionStorage.getItem(B2B_PORTAL_AUTH_REDIRECT_TO);
    if (redirectTo) {
      try {
        redirectToSessionStorageUrl(redirectTo);
      } catch {
        // If the URL is not allowed, redirect to the home page
        sessionStorage.removeItem(B2B_PORTAL_AUTH_REDIRECT_TO);
        window.location.replace(PATH_B2B_HOME);
      }
    } else {
      history.push(PATH_FLIGHTS_HOME);
    }
  };

  const [currentStep, setCurrentStep] = React.useState<FtuxStep>(
    FtuxStep.Step0
  );

  const [rewardsAccount, setRewardsAccount] =
    React.useState<RewardsAccount | null>(null);

  const headingRef = createRef<HTMLSpanElement>();

  useEffect(() => {
    if (FTUXRemovalVariant === AVAILABLE) {
      handleRedirect();
    } else {
      fetchRewards().then((result) => {
        fetchWalletDetails().then((walletDetails) => {
          if (result) {
            const largestRewardsAccount: RewardsAccount =
              getLargestRewardsAccount(result);
            trackLaunchedApp(
              result,
              walletDetails?.creditBreakdown || [],
              walletDetails?.credit,
              expState.trackingProperties
            );
            setRewardsAccount(largestRewardsAccount);
          }
        });
      });
      trackEvent({
        eventName: VIEWED_ONBOARDING,
        properties: {},
      });
    }
  }, [expState]);

  useEffect(() => {
    headingRef.current?.focus();
  }, [currentStep]);

  const getHeaderText = useCallback((): string => {
    switch (currentStep) {
      case FtuxStep.Step1:
        return constants.HEADER_ONE(sessionInfo?.userInfo?.firstName ?? "");
      case FtuxStep.Step2:
        return constants.HEADER_TWO;
      case FtuxStep.Step3:
        return constants.HEADER_THREE;
      default:
        return "";
    }
  }, [currentStep]);

  const handleContinue = useCallback(() => {
    switch (currentStep) {
      case FtuxStep.Step3:
        const properties = addTrackingProperties(expState.trackingProperties);
        trackEvent({
          eventName: COMPLETED_ONBOARDING,
          properties: properties,
        });
        handleRedirect();
        break;
      default:
        setCurrentStep((step) => step + 1);
        break;
    }
  }, [expState, currentStep]);

  const handleSkip = useCallback(() => {
    trackEvent({
      eventName: SKIPPED_ONBOARDING,
      properties: {
        ...expState?.trackingProperties,
      },
    });
    handleRedirect();
  }, [expState]);

  useEffect(() => {
    if (rewardsAccount && sessionInfo && currentStep < FtuxStep.Step1) {
      setCurrentStep(FtuxStep.Step1);
    }
  }, [rewardsAccount, sessionInfo, currentStep]);

  const showRewards = rewardsAccount?.allowRewardsRedemption ?? true;

  const mobileScreenshot = showRewards
    ? clientContext.priceDropScreenshotMobile
    : clientContext.priceDropScreenshotNoRewardsMobile;
  const desktopScreenshot = showRewards
    ? clientContext.priceDropScreenshotDesktop
    : clientContext.priceDropScreenshotNoRewardsDesktop;

  // note: FtuxStep.Step0 indicates that it's still fetching data
  if (currentStep < FtuxStep.Step1) {
    return <LoadingScreen language={language} />;
  } else {
    return (
      <Box
        className={clsx("first-time-user-page-root", { mobile: matchesMobile })}
      >
        <Box className="first-time-user-page-container">
          <Box className="content-wrapper">
            <Box className={clsx("icon-section", `step-${currentStep}`)}>
              {clientContext.logo}
            </Box>
            <Box className="header-section">
              <Typography
                className="header-text"
                variant="h1"
                ref={headingRef}
                tabIndex={0}
              >
                {getHeaderText()}
              </Typography>
            </Box>
            {currentStep === FtuxStep.Step3 ? (
              <>
                <Box className="subheading-section">
                  <Typography className="subheading-text" variant="h1">
                    {constants.STEP_THREE_SUBHEADING}
                  </Typography>
                </Box>
                <Box className="screen-shot-section">
                  {matchesMobile ? mobileScreenshot : desktopScreenshot}
                </Box>
              </>
            ) : (
              <BulletOptions
                currentStep={currentStep}
                rewardsAccount={rewardsAccount}
              />
            )}
            <Box className={clsx("bottom-section", `step-${currentStep}`)}>
              <Box className="bottom-content-wrapper">
                <ActionButton
                  className="ftux-button-wrapper"
                  buttonClassName="ftux-button"
                  onClick={handleContinue}
                  message={
                    <Typography className="ftux-button-text" variant="h3">
                      {currentStep === FtuxStep.Step3
                        ? constants.BEGIN_EXPLORING
                        : constants.CONTINUE}
                    </Typography>
                  }
                />
                <Typography className="current-step-text" variant="h3">
                  {constants.CURRENT_STEP_TEXT(currentStep)}
                </Typography>
                {currentStep !== FtuxStep.Step3 && (
                  <ActionLink
                    className="ftux-skip-button"
                    onClick={handleSkip}
                    content={
                      <Typography className="ftux-skip-button-text">
                        {constants.SKIP_TO_FLIGHT}
                      </Typography>
                    }
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export const FirstTimeUserPage = (props: IFirstTimeUserPageProps) => {
  const [sessionInfo, setSessionInfo] = React.useState<SessionInfo | null>(
    null
  );

  useEffect(() => {
    fetchUser().then((result) => {
      if (result) {
        setSessionInfo(result);
      }
    });
  }, []);

  if (!!sessionInfo) {
    return (
      <ExperimentsProvider isLoggedIn={!!sessionInfo.csrfToken}>
        <FirstTimeUserContent props={props} sessionInfo={sessionInfo} />
      </ExperimentsProvider>
    );
  } else {
    return <LoadingScreen language={props.language} />;
  }
};
