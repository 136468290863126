import { ICorpPerson, IPerson } from "redmond";
import { IHotelLoyaltyProgram } from "./hotelLoyaltyProgramList";

export const getUpdatedTraveler = (
  traveler: IPerson,
  loyaltyProgram: IHotelLoyaltyProgram | null,
  newLoyaltyNumber?: string,
  newEmail?: string
) => {
  const isHotelLoyaltyUpdate = newLoyaltyNumber !== undefined;
  let newHotelLoyalty = {};
  if (isHotelLoyaltyUpdate && loyaltyProgram) {
    if (newLoyaltyNumber === "") {
      newHotelLoyalty = {
        ...traveler.hotelLoyalty,
      };
      delete newHotelLoyalty[loyaltyProgram.loyaltyProgramCode];
    } else {
      newHotelLoyalty = {
        ...traveler.hotelLoyalty,
        [loyaltyProgram.loyaltyProgramCode]: { value: newLoyaltyNumber },
      };
    }
  }
  const updatedTraveler = {
    ...traveler,
    ...(isHotelLoyaltyUpdate && { hotelLoyalty: newHotelLoyalty }),
    ...(newEmail && { emailAddress: newEmail }),
  } satisfies IPerson | ICorpPerson;

  return updatedTraveler;
};
