import { getConfigTenant } from "@capone/common";

// Use process.env when running Pawtucket modules on their own, otherwise use runtime config from McLean
export const BASE_URL = window.__mclean_env__
  ? window.__mclean_env__.REACT_APP_TYSONS_URL
  : "";

const TENANT = getConfigTenant();

export const config = {
  // TODO implement auth when BE complete
  apiKey: "test",
  baseURL: BASE_URL!,
  TENANT,
};
