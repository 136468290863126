export interface IHotelLoyaltyProgram {
  hotelChainName: string;
  loyaltyProgramName: string;
  loyaltyProgramCode: string;
  label?: JSX.Element;
}

// Source list is maintained in the following Google Sheet:
// https://docs.google.com/spreadsheets/d/1dSIwws2JV9ylY8IDS3Ws6VwBaknAFyHC0attXSphuqY/edit#gid=1531209728
export const hotelLoyaltyProgramList: IHotelLoyaltyProgram[] = [
  {
    loyaltyProgramName: "ALL - Accor Live Limitless",
    loyaltyProgramCode: "ACH",
    hotelChainName: "Accor Hotels",
  },
  {
    loyaltyProgramName: "Anantara Discovery",
    loyaltyProgramCode: "ANA",
    hotelChainName: "Anantara",
  },
  {
    loyaltyProgramName: "Ascott Star Rewards",
    loyaltyProgramCode: "ASR",
    hotelChainName: "The Ascott Limited",
  },
  {
    loyaltyProgramName: "Best Western Rewards",
    loyaltyProgramCode: "BWH",
    hotelChainName: "BWH Hotel Group",
  },
  {
    loyaltyProgramName: "Choice Privileges",
    loyaltyProgramCode: "CHH",
    hotelChainName: "Choice Hotels",
  },
  {
    loyaltyProgramName: "Coast Rewards",
    loyaltyProgramCode: "COH",
    hotelChainName: "Coast Hotels",
  },
  {
    loyaltyProgramName: "H Rewards",
    loyaltyProgramCode: "DEH",
    hotelChainName: "Deutsche Hospitality",
  },
  {
    loyaltyProgramName: "Dusit Gold",
    loyaltyProgramCode: "DHR",
    hotelChainName: "Dusit Hotels & Resorts",
  },
  {
    loyaltyProgramName: "Drury Rewards",
    loyaltyProgramCode: "DRH",
    hotelChainName: "Drury Hotels",
  },
  {
    loyaltyProgramName: "Extendedperks",
    loyaltyProgramCode: "ESA",
    hotelChainName: "Extended Stay America",
  },
  {
    loyaltyProgramName: "Fiesta Rewards",
    loyaltyProgramCode: "FIA",
    hotelChainName: "Fiesta Americana",
  },
  {
    loyaltyProgramName: "GHA Discovery",
    loyaltyProgramCode: "GHA",
    hotelChainName: "Global Hotel Alliance",
  },
  {
    loyaltyProgramName: "Hilton Honors",
    loyaltyProgramCode: "HIL",
    hotelChainName: "Hilton",
  },
  {
    loyaltyProgramName: "City Premios",
    loyaltyProgramCode: "HOC",
    hotelChainName: "Hoteles City",
  },
  {
    loyaltyProgramName: "Unity by Hard Rock",
    loyaltyProgramCode: "HRH",
    hotelChainName: "Hard Rock Hotels",
  },
  {
    loyaltyProgramName: "World of Hyatt",
    loyaltyProgramCode: "HYA",
    hotelChainName: "Hyatt",
  },
  {
    loyaltyProgramName: "IHG One Rewards",
    loyaltyProgramCode: "IHG",
    hotelChainName: "IHG",
  },
  {
    loyaltyProgramName: "Jumeirah One",
    loyaltyProgramCode: "JHR",
    hotelChainName: "Jumeirah Hotels & Resorts",
  },
  {
    loyaltyProgramName: "Langham 1865",
    loyaltyProgramCode: "LHG",
    hotelChainName: "Langham Hospitality Group",
  },
  {
    loyaltyProgramName: "Leaders Club",
    loyaltyProgramCode: "LHW",
    hotelChainName: "Leading Hotels of the World",
  },
  {
    loyaltyProgramName: "Loews MVP Rewards Program",
    loyaltyProgramCode: "LOH",
    hotelChainName: "Loews Hotels",
  },
  {
    loyaltyProgramName: "Leela DISCOVERY",
    loyaltyProgramCode: "LPH",
    hotelChainName: "Leela Palaces, Hotels and Resorts",
  },
  {
    loyaltyProgramName: "Make it Maldron",
    loyaltyProgramCode: "MAH",
    hotelChainName: "Maldron Hotels",
  },
  {
    loyaltyProgramName: "Marriott Bonvoy",
    loyaltyProgramCode: "MAR",
    hotelChainName: "Marriott",
  },
  {
    loyaltyProgramName: "Melia Rewards",
    loyaltyProgramCode: "MEL",
    hotelChainName: "Melia",
  },
  {
    loyaltyProgramName: "My Millennium Millennium Hotels and Resorts",
    loyaltyProgramCode: "MIL",
    hotelChainName: "Millenium",
  },
  {
    loyaltyProgramName: "My6",
    loyaltyProgramCode: "MO6",
    hotelChainName: "Motel 6",
  },
  {
    loyaltyProgramName: "Fans of MO",
    loyaltyProgramCode: "MOH",
    hotelChainName: "Mandarin Oriental Hotel Group",
  },
  {
    loyaltyProgramName: "NH DISCOVERY",
    loyaltyProgramCode: "NHH",
    hotelChainName: "NH Hotel Group",
  },
  {
    loyaltyProgramName: "Noble Rewards",
    loyaltyProgramCode: "NOH",
    hotelChainName: "Noble House Hotels & Resorts",
  },
  {
    loyaltyProgramName: "Select Guest",
    loyaltyProgramCode: "OMH",
    hotelChainName: "Omni Hotels",
  },
  {
    loyaltyProgramName: "Discovery",
    loyaltyProgramCode: "PAN",
    hotelChainName: "Pan Pacific",
  },
  {
    loyaltyProgramName: "I Prefer",
    loyaltyProgramCode: "PRH",
    hotelChainName: "Preferred Hotels",
  },
  {
    loyaltyProgramName: "Quest Advantage",
    loyaltyProgramCode: "QAH",
    hotelChainName: "Quest Apartment Hotels",
  },
  {
    loyaltyProgramName: "Radisson Rewards",
    loyaltyProgramCode: "RAH",
    hotelChainName: "Radisson Hotels",
  },
  {
    loyaltyProgramName: "RediRewards",
    loyaltyProgramCode: "RER",
    hotelChainName: "Red Roof",
  },
  {
    loyaltyProgramName: "Hello Rewards",
    loyaltyProgramCode: "RLH",
    hotelChainName: "Red Lion Hotels",
  },
  {
    loyaltyProgramName: "Scandic Hotels",
    loyaltyProgramCode: "SCH",
    hotelChainName: "Scandic Hotels",
  },
  {
    loyaltyProgramName: "SLH Invited",
    loyaltyProgramCode: "SLH",
    hotelChainName: "Small Luxury Hotels",
  },
  {
    loyaltyProgramName: "Sonesta Travel Pass",
    loyaltyProgramCode: "SON",
    hotelChainName: "Sonesta",
  },
  {
    loyaltyProgramName: "VHG Rewards",
    loyaltyProgramCode: "VHG",
    hotelChainName: "Valencia Hotel Group",
  },
  {
    loyaltyProgramName: "WorldHotels Rewards",
    loyaltyProgramCode: "WHC",
    hotelChainName: "World Hotels Collection",
  },
  {
    loyaltyProgramName: "Wyndham Rewards",
    loyaltyProgramCode: "WYN",
    hotelChainName: "Wyndham Hotels",
  },
];

const loyaltyProgramCodeMap = hotelLoyaltyProgramList.reduce(
  (acc, loyaltyProgram) => {
    acc[loyaltyProgram.loyaltyProgramCode] = loyaltyProgram;
    return acc;
  },
  {} as Record<string, IHotelLoyaltyProgram>
);

export type LoyaltyProgramCode = keyof typeof loyaltyProgramCodeMap;

export const getHotelLoyaltyProgram = (loyaltyProgramCode: string) => {
  if (!loyaltyProgramCodeMap[loyaltyProgramCode]) {
    return null;
  }

  return loyaltyProgramCodeMap[loyaltyProgramCode];
};
