import axios from "axios";
import { UntypedExperiment, CorpExperimentsMap } from "../types";
import { config } from "./config";

const END_POINT = `/api/v0/experiments/active`;
const JsonExperiments = new Set(["corp-traveler-maintenance"]);

const createCorpExperimentsMap = (
  experiments: UntypedExperiment[]
): CorpExperimentsMap => {
  const CorpExperimentsMap = experiments.reduce((acc, experiment) => {
    if (JsonExperiments.has(experiment.id)) {
      // JSON Experiments
      const { variantName, data } = JSON.parse(experiment.variant);

      acc[experiment.id] = {
        id: experiment.id,
        variant: variantName,
        data,
      };
    } else {
      // String Experiments
      acc[experiment.id] = experiment;
    }

    return acc;
  }, {} as Partial<CorpExperimentsMap>) as CorpExperimentsMap;

  return CorpExperimentsMap;
};

export const fetchActiveExperiments = async () => {
  const { data } = await axios.put(END_POINT, {}, { baseURL: config.baseURL });

  return {
    experiments: createCorpExperimentsMap(data.experiments),
    trackingProperties: data.trackingProperties,
  };
};
