import { useEffect } from "react";

const useWindowEventListener = (eventName: string, eventHandler: any) => {
  useEffect(() => {
    window.addEventListener(eventName, eventHandler);
    return () => window.removeEventListener(eventName, eventHandler, false);
  }, []);
};

export default useWindowEventListener;
