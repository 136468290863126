import { CorpRoomInfoProducts } from "redmond";
import { getConfigTenant, isCaponeTenant } from "@capone/common";

export const getRoomPolicyComplianceType = (
  roomInfoProducts: CorpRoomInfoProducts[]
) => {
  if (isCaponeTenant(getConfigTenant())) {
    return "none";
  }
  const roomsInPolicy = roomInfoProducts.filter(
    (room) => room.roomInfo.corporateTravel.policyCompliance.isInPolicy
  );
  if (roomsInPolicy.length === 0) {
    return "none";
  } else if (roomsInPolicy.length < roomInfoProducts.length) {
    return "some";
  } else {
    return "all";
  }
};
