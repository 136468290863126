import React from "react";

type ExperimentsHookContext = {
  isLoggedIn: boolean;
};

export const ExperimentsHookContext = React.createContext<
  ExperimentsHookContext | undefined
>(undefined);

export const ExperimentsHookProvider: React.FC<
  React.PropsWithChildren<{
    isLoggedIn: boolean;
  }>
> = ({ isLoggedIn, children }) => {
  return (
    <ExperimentsHookContext.Provider value={{ isLoggedIn }}>
      {children}
    </ExperimentsHookContext.Provider>
  );
};
