import { Box, Typography } from "@material-ui/core";
import React from "react";

import { IconName, Icon } from "halifax";
import { RewardsAccount } from "redmond";
import * as constants from "./constants";
import { FtuxStep } from "./component";
import clsx from "clsx";

interface IBulletOptionProps {
  iconName: IconName;
  text: string;
}

// note: <Venture X> card
const satisfiesVariantOne = (rewards: RewardsAccount): boolean => {
  const acceptedCards = ["Venture"];
  const productDisplayNameSplitted = rewards.productDisplayName.split(" ");
  return (
    acceptedCards.includes(productDisplayNameSplitted[0]) &&
    productDisplayNameSplitted.length > 1
  );
};

// note: <Venture/VentureOne/Capital One Walmart Rewards> card
const satisfiesVariantTwo = (rewards: RewardsAccount): boolean => {
  const acceptedCards = [
    "Venture",
    "VentureOne",
    "Capital One Walmart Rewards",
  ];
  return acceptedCards.includes(rewards.productDisplayName);
};

export const getBulletOptions = (
  currentStep: FtuxStep,
  rewardsAccount: RewardsAccount | null
): IBulletOptionProps[] => {
  switch (currentStep) {
    case FtuxStep.Step1:
      let bulletTwoText: string = constants.STEP_ONE_BULLET_TWO_GENERAL;
      if (rewardsAccount) {
        let currency = rewardsAccount.rewardsBalance.currency.toLowerCase();
        if (satisfiesVariantOne(rewardsAccount)) {
          bulletTwoText = constants.STEP_ONE_BULLET_TWO_VARIANT_ONE(
            currency,
            rewardsAccount.productDisplayName
          );
        } else if (satisfiesVariantTwo(rewardsAccount)) {
          bulletTwoText = constants.STEP_ONE_BULLET_TWO_VARIANT_TWO(
            currency,
            rewardsAccount.productDisplayName
          );
        }
      }

      return [
        { iconName: IconName.Checked, text: constants.STEP_ONE_BULLET_ONE },
        {
          iconName: IconName.Checked,
          text: bulletTwoText,
        },
        { iconName: IconName.Checked, text: constants.STEP_ONE_BULLET_THREE },
      ];
    case FtuxStep.Step2:
      return [
        { iconName: IconName.Guarantee, text: constants.STEP_TWO_BULLET_ONE },
        { iconName: IconName.BellAlert, text: constants.STEP_TWO_BULLET_TWO },
        {
          iconName: IconName.PriceDrop,
          text: constants.STEP_TWO_BULLET_THREE,
        },
      ];
    default:
      return [];
  }
};

export const BulletOption = (props: IBulletOptionProps) => {
  const { iconName, text } = props;
  return (
    <Box className="bullet-option-container">
      <Icon className="bullet-icon" name={iconName} />
      <Typography className="bullet-text" variant="h2">
        {text}
      </Typography>
    </Box>
  );
};

interface IBulletOptionsProps {
  currentStep: FtuxStep;
  rewardsAccount: RewardsAccount | null;
}

const BulletOptions = (props: IBulletOptionsProps) => {
  const { currentStep, rewardsAccount } = props;
  return (
    <Box className={clsx("bullets-section", `step-${currentStep}`)}>
      {getBulletOptions(currentStep, rewardsAccount).map((option, index) => (
        <BulletOption key={index} {...option} />
      ))}
    </Box>
  );
};

export default BulletOptions;
