import {
  CorpBusinessAccount,
  CorpSessionInfo,
  RewardsAccount,
  SessionInfo,
} from "redmond";

export const SET_SESSION_INFO = "b2bBase/SET_SESSION_INFO";
export type SET_SESSION_INFO = typeof SET_SESSION_INFO;

export interface ISetSessionInfo {
  type: SET_SESSION_INFO;
  sessionInfo: SessionInfo | CorpSessionInfo;
}

export const setSessionInfoInStore = (
  sessionInfo: SessionInfo | CorpSessionInfo
): ISetSessionInfo => ({
  type: SET_SESSION_INFO,
  sessionInfo,
});

export const SET_BUSINESS_ACCOUNTS = "b2bBase/SET_BUSINESS_ACCOUNTS";
export type SET_BUSINESS_ACCOUNTS = typeof SET_BUSINESS_ACCOUNTS;

export interface ISetBusinessAccounts {
  type: SET_BUSINESS_ACCOUNTS;
  businessAccounts: CorpBusinessAccount[];
}

export const setBusinessAccounts = (
  businessAccounts: CorpBusinessAccount[]
): ISetBusinessAccounts => ({
  type: SET_BUSINESS_ACCOUNTS,
  businessAccounts,
});

export const SET_REWARDS_ACCOUNTS = "b2bBase/SET_REWARDS_ACCOUNTS";

export type SET_REWARDS_ACCOUNTS = typeof SET_REWARDS_ACCOUNTS;

export interface ISetRewardsAccounts {
  type: SET_REWARDS_ACCOUNTS;
  rewardsAccounts: RewardsAccount[];
}

export const setRewardsAccountsInStore = (
  rewardsAccounts: RewardsAccount[]
): ISetRewardsAccounts => ({
  type: SET_REWARDS_ACCOUNTS,
  rewardsAccounts,
});

export const SET_SELECTED_ACCOUNT_REFERENCE_ID =
  "b2bBase/SET_SELECTED_ACCOUNT_REFERENCE_ID";

export type SET_SELECTED_ACCOUNT_REFERENCE_ID =
  typeof SET_SELECTED_ACCOUNT_REFERENCE_ID;

export interface ISetSelectedAccountReferenceId {
  type: SET_SELECTED_ACCOUNT_REFERENCE_ID;
  selectedAccountReferenceId: string;
}

export const setSelectedAccountReferenceIdInStore = (
  selectedAccountReferenceId: string
): ISetSelectedAccountReferenceId => ({
  type: SET_SELECTED_ACCOUNT_REFERENCE_ID,
  selectedAccountReferenceId,
});

export type B2bBaseActions =
  | ISetSessionInfo
  | ISetBusinessAccounts
  | ISetRewardsAccounts
  | ISetSelectedAccountReferenceId;
