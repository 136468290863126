import axios from "axios";
import { config } from "../../config";
import { loginApiPath } from "../paths";
import { LoginPostRequest, LoginPostResponse } from "redmond";

export const postLogin = (body: LoginPostRequest): Promise<LoginPostResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(loginApiPath, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
