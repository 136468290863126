import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Experiment, ExperimentState } from "redmond";
import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";

// Active Experiments
export const FTUX_REMOVAL = "c1-marketplace-ftux-removal";

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";

const defaultInitializer = (): ExperimentState => {
  return {
    experiments: [],
    trackingProperties: undefined,
  };
};

export const ExperimentsContext = createContext<ExperimentState | undefined>(
  undefined
);

export function getExperimentVariant(
  experiments: Array<Experiment>,
  experimentId: String
): string {
  const experiment = experiments?.find((exp) => {
    if (exp.id === experimentId) {
      return exp;
    }
    return;
  });

  if (experiment) {
    return experiment.variant;
  } else {
    return CONTROL;
  }
}

export function useExperiments(): ExperimentState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties?: any
): unknown {
  if (!properties) {
    properties = {};
  }
  if (trackingProperties) {
    properties["experiments"] = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties![ex]}`
    );
  }
  return properties;
}

type ExperimentsProviderProps = {
  initState?: ExperimentState;
  isLoggedIn?: boolean;
};

const ExperimentsProvider: FC<ExperimentsProviderProps & PropsWithChildren> = ({
  initState = defaultInitializer(),
  isLoggedIn,
  children,
}) => {
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    let isMounted = true;
    if (!initState?.experiments.length && isLoggedIn) {
      const fetchExperiments = async (): Promise<ExperimentState> => {
        return await fetchActiveExperiments().then((result) => {
          return result;
        });
      };
      fetchExperiments().then((result) => {
        if (isMounted) {
          setState(result);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isLoggedIn]);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
