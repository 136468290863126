import React, { useEffect, useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName, ActionButton, useDeviceTypes } from "halifax";
import {
  B2B_PORTAL_AUTH_INVALID_SESSION,
  B2B_PORTAL_AUTH_REDIRECT_TO,
  LoginFailedReason,
  LoginFailedReasonEnum,
} from "redmond";
import clsx from "clsx";
import { fetchLoginCode } from "../../api/v1/auth/";
import { ClientContext } from "../../App";
import { LoadingScreen } from "../LoadingScreen";
import * as constants from "./constants";
import "./styles.scss";

interface IUnauthorizedPageProps {
  language: string;
}

const UnauthorizedPage = (props: IUnauthorizedPageProps) => {
  const { language } = props;
  const [errorScreenProps, setErrorScreenProps] =
    React.useState<IErrorScreenProps | null>(null);
  const clientContext = useContext(ClientContext);

  const handleUnauthorized = async () => {
    let response = await fetchLoginCode();
    setTimeout(() => {
      window.location.replace(response.loginUri);
    }, 500);
  };

  useEffect(() => {
    const redirectUrl = new URLSearchParams(window.location.search).get(
      "redirectUrl"
    );
    if (redirectUrl) {
      sessionStorage.setItem(B2B_PORTAL_AUTH_REDIRECT_TO, redirectUrl);
    }
  }, []);

  useEffect(() => {
    const sessionData = sessionStorage.getItem(B2B_PORTAL_AUTH_INVALID_SESSION);
    if (sessionData) {
      sessionStorage.removeItem(B2B_PORTAL_AUTH_INVALID_SESSION);
      const response = JSON.parse(sessionData) as LoginFailedReason;
      switch (response.LoginFailedReason) {
        case LoginFailedReasonEnum.NoEligibleAccount: {
          setErrorScreenProps({
            headerText: constants.INELIGIBLE_WARNING_TEXT,
            buttonText: constants.INELIGIBLE_BUTTON_LABEL,
            urlLink: clientContext.ineligibleAccountRedirectURL ?? "",
          });
          break;
        }
        default: {
          setErrorScreenProps({
            headerText: constants.BAD_STATUS_WARNING_TEXT,
            buttonText: constants.BAD_STATUS_BUTTON_LABEL,
            urlLink: clientContext.badStatusRedirectURL ?? "",
          });
          break;
        }
      }
    } else {
      handleUnauthorized();
    }
  }, []);

  return (
    <Box className="unauthorized-page-container">
      {errorScreenProps ? (
        <ErrorScreen {...errorScreenProps} />
      ) : (
        <LoadingScreen language={language} />
      )}
    </Box>
  );
};

export default UnauthorizedPage;

interface IErrorScreenProps {
  headerText: string;
  buttonText: string;
  urlLink: string;
}

const ErrorScreen = (props: IErrorScreenProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { headerText, buttonText, urlLink } = props;

  return (
    <Box className={clsx("error-screen-root", { mobile: matchesMobile })}>
      <Box className="error-screen-container">
        <Box className="content-wrapper">
          <Box className="icon-section">
            <Icon className="error-icon" name={IconName.ErrorState} />
          </Box>
          <Box className="header-section">
            <Typography className="header-text" variant="h5">
              {headerText}
            </Typography>
          </Box>
          <Box className="bottom-section">
            <ActionButton
              className="redirect-button-wrapper"
              buttonClassName="redirect-button"
              defaultStyle="h4r-primary"
              onClick={() => window.open(urlLink, "_self")}
              message={
                <Typography
                  className="redirect-button-text"
                  variant="subtitle2"
                >
                  {buttonText}
                </Typography>
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
