import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { B2BSpinner, useDeviceTypes } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import { ClientContext } from "../../App";
import * as constants from "./constants";

interface ILoadingScreenProps {
  language: string;
}

export const LoadingScreen = (_: ILoadingScreenProps) => {
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);

  return (
    <Box className={clsx("loading-screen-root", { mobile: matchesMobile })}>
      <Box className="loading-screen-container">
        <Box className="content-wrapper">
          <Box className="icon-section">{clientContext.logo}</Box>
          <Box className="bottom-section">
            <B2BSpinner style={{ width: "50px", height: "50px" }} />
            <Typography className="loading-text" variant="h4">
              {constants.LOADING}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
