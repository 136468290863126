import React from "react";
import { Tenant, cardsToShowLastFourOnRewardsBanner } from "redmond";
import { isCorpTenant } from "../utils/getTenant";

export const DO_NOT_APPLY_REWARDS_KEY = "do-not-apply-rewards";

export const REWARDS_BANNER_TEXT = ({
  firstName,
  earnRate,
  flightEarnRate,
  carEarnRate,
  productId,
  lastFour,
  currency,
  isPrimary,
  tenant,
}: {
  firstName: string;
  earnRate: string | number;
  carEarnRate?: string | number;
  flightEarnRate: string | number;
  productId: string;
  lastFour: string;
  currency: string;
  isPrimary: boolean;
  tenant: Tenant;
}) => {
  const isCorp = isCorpTenant(tenant);
  const multiplier = currency.includes("cash") ? "%" : "X";

  let earnString = "";

  if (isCorp) {
    if (!isPrimary) {
      return `Hi ${firstName}, travel smarter and get 24/7 traveler support with Capital One Business Travel.`;
    }
    earnString =
      flightEarnRate && carEarnRate && flightEarnRate === carEarnRate
        ? `Hi ${firstName}, earn ${flightEarnRate}X ${currency} on flights and rental cars when you or employees book with your ${productId} account`
        : flightEarnRate
        ? `Hi ${firstName}, earn ${flightEarnRate}${multiplier} ${currency} on flights and ${earnRate}${multiplier} ${currency} on hotels and rental cars when you or employees book with your ${productId} account`
        : `Hi ${firstName}, earn ${earnRate}${multiplier} ${currency} on hotels and rental cars when you or employees book with your ${productId} account`;
  } else {
    if (productId.toLowerCase().includes("paradise")) {
      return `Capital One Travel helps cardholders book confidently, with smarter tools and thousands of trip options.`;
    } else {
      earnString =
        flightEarnRate && carEarnRate && flightEarnRate === carEarnRate
          ? `Hi ${firstName}, earn ${flightEarnRate}X ${currency} on flights and rental cars when you book with your ${productId} account`
          : flightEarnRate
          ? `Hi ${firstName}, earn ${flightEarnRate}${multiplier} ${currency} on flights and ${earnRate}${multiplier} ${currency} on hotels and rental cars when you book with your ${productId} account`
          : `Hi ${firstName}, earn ${earnRate}${multiplier} ${currency} on hotels and rental cars when you book with your ${productId} account`;
    }
  }

  earnString += cardsToShowLastFourOnRewardsBanner.includes(
    productId.toLowerCase()
  )
    ? ` ending in ${lastFour}.`
    : `.`;
  return earnString;
};

// capone-corporate travel users clarity redirect param
export const VIEW_PARAM = "view";
export const USERS_CLARITY_PARAM_VALUE_ADD = "add_traveler";
export const USERS_CLARITY_PARAM_VALUE_LIST = "list_traveler";

//HOME PAGE
export const CORP_HOMEPAGE_TITLE = (
  <React.Fragment>Welcome to Capital One Business Travel</React.Fragment>
);
export const CORP_HOMEPAGE_SUBTITLE = "The smarter way to book business travel";

export const CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT = <>Welcome, DBC America</>;

export const OUT_OF_POLICY_REWARDS_BANNER =
  "Rewards can’t be applied to out-of-policy booking requests.";

export const AMADEUS = "Amadeus";
