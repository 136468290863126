import axios from "axios";
import { config } from "../../config";
import { loginCodeApiPath } from "../paths";

export const fetchLoginCode = (): Promise<{ loginUri: string }> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(loginCodeApiPath, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
