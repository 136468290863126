import axios from "axios";
import { config } from "../../config";
import { logoutApiPath } from "../paths";

export const postLogout = (): Promise<{ destinationUrl: string }> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        logoutApiPath,
        {},
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
