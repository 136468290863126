import { useContext, useEffect } from "react";
import { createGlobalState } from "react-use";
import { CorpExperimentsMap } from "../types";
import { fetchActiveExperiments } from "../api";
import { ExperimentsHookContext } from "./ExperimentsHookProvider";

type ExperimentsState = {
  experiments: CorpExperimentsMap;
  trackingProperties: Record<string, string>;
};

const useExperimentsHook = createGlobalState<ExperimentsState | undefined>();
const useActiveExperimentsPromise = createGlobalState<
  Promise<ExperimentsState> | undefined
>(undefined);

export const useExperiments = () => {
  const ctx = useContext(ExperimentsHookContext);
  const isLoggedIn = Boolean(ctx?.isLoggedIn);

  const [state, setState] = useExperimentsHook();
  const [promise, setPromise] = useActiveExperimentsPromise();

  const experiments = state?.experiments || ({} as CorpExperimentsMap);

  useEffect(() => {
    // This effect will wait for auth to be set before triggering Experiments API call.
    // And sets the promise singleton so we only fetch once.
    if (isLoggedIn && !promise) {
      setPromise(fetchActiveExperiments());
    }
  }, [isLoggedIn, promise]);

  useEffect(() => {
    // Wait for the fetchActiveExperiments promise to exist, and then resolve it into experiments.
    const resolvePromiseSingleton = async () => {
      const result = await promise;
      setState(result);
    };

    if (promise) {
      resolvePromiseSingleton();
    }
  }, [promise]);

  return experiments;
};
