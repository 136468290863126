import {
  CorpBusinessAccount,
  CorpSessionInfo,
  RewardsAccount,
  SessionInfo,
} from "redmond";
import {
  B2bBaseActions,
  SET_BUSINESS_ACCOUNTS,
  SET_REWARDS_ACCOUNTS,
  SET_SELECTED_ACCOUNT_REFERENCE_ID,
  SET_SESSION_INFO,
} from "./actions";
export interface B2bBaseState {
  sessionInfo: SessionInfo | CorpSessionInfo | undefined;
  corpBusinessAccounts?: CorpBusinessAccount[];
  rewardsAccounts: RewardsAccount[];
  selectedAccountReferenceId: string | null;
}

const initialState: B2bBaseState = {
  sessionInfo: undefined,
  corpBusinessAccounts: undefined,
  rewardsAccounts: [],
  selectedAccountReferenceId: null,
};

export function rootReducer(
  state = initialState,
  action: B2bBaseActions
): B2bBaseState {
  switch (action.type) {
    case SET_SESSION_INFO:
      return {
        ...state,
        sessionInfo: action.sessionInfo,
      };
    case SET_BUSINESS_ACCOUNTS:
      return {
        ...state,
        corpBusinessAccounts: action.businessAccounts,
      };
    case SET_REWARDS_ACCOUNTS:
      return {
        ...state,
        rewardsAccounts: action.rewardsAccounts,
      };

    case SET_SELECTED_ACCOUNT_REFERENCE_ID:
      return {
        ...state,
        selectedAccountReferenceId: action.selectedAccountReferenceId,
      };
    default:
      return state;
  }
}
