import {
  isIOS,
  isAndroid,
  deviceType,
  mobileModel,
  browserName,
  osName,
} from "react-device-detect";

const getPlatform = () => {
  if (isIOS) return "c1";
  if (isAndroid) return "m1";
  return "w1";
};

export const getUserDeviceData = () => {
  return {
    browserName,
    $os: osName,
    $os_version: osName,
    osName: osName,
    platform: getPlatform(),
    device_type: deviceType,
    deviceType: deviceType,
    mp_device_model: mobileModel !== "none" ?  mobileModel : deviceType,
    device: mobileModel !== "none" ?  mobileModel : deviceType,
    $model: mobileModel !== "none" ?  mobileModel : deviceType,
    $brand: deviceType,
    $manufacturer: deviceType,
  };
};
