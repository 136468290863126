import axios from "axios";
import { config } from "../../config";
import { loginCodeApiPath } from "../paths";
import { LoginCodePostRequest, LoginCodePostResponse } from "redmond";

export const postLoginCode = (
  body: LoginCodePostRequest
): Promise<LoginCodePostResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(loginCodeApiPath, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
