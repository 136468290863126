export const apiVersionPrefix: string = "/api/v0";

export const loginCodeApiPath: string = `${apiVersionPrefix}/login/code`;
export const loginApiPath: string = `${apiVersionPrefix}/login`;
export const logoutApiPath: string = `${apiVersionPrefix}/logout`;

export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;

export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;

export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;

export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;
export const travelWalletApiPrefix: string = `${apiVersionPrefix}/user/wallet`;
