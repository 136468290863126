import { HotelLoyaltyMap } from "redmond";

export interface IHotelLoyalty {
  key: string;
  value: string;
  trackingId: number;
}

export type IHotelLoyaltyList = IHotelLoyalty[];

export const getHotelLoyaltyList = (
  hotelLoyaltyMap: HotelLoyaltyMap
): IHotelLoyaltyList =>
  Object.entries(hotelLoyaltyMap).map((hotelLoyalty, index) => ({
    key: hotelLoyalty[0],
    value: hotelLoyalty[1].value,
    trackingId: index,
  }));

export const getHotelLoyaltyMap = (
  hotelLoyaltyList: IHotelLoyaltyList
): HotelLoyaltyMap => {
  const hotelLoyaltyMap: HotelLoyaltyMap = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const hotelLoyalty of hotelLoyaltyList) {
    hotelLoyaltyMap[hotelLoyalty.key] = {
      value: hotelLoyalty.value,
    };
  }

  return hotelLoyaltyMap;
};
