import { CorpSessionInfo, RewardsAccount } from "redmond";

export const getBusinessEligibleAccounts = (
  accounts: RewardsAccount[],
  userInfo?: CorpSessionInfo
) => {
  return accounts.filter((account) =>
    userInfo
      ? account.accountReferenceId === userInfo.corporateInfo.accountReferenceId
      : isCorporateRewardsAccount(account)
  );
};

export const isCorporateRewardsAccount = (account: RewardsAccount) =>
  account.productDisplayName.startsWith("Spark") ||
  account.productDisplayName.toLowerCase().includes("business");

export const isConsumerRewardsAccount = (account: RewardsAccount) =>
  !isCorporateRewardsAccount(account);
