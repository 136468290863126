export const eventsToListen = {
  HOST_THEME_CHANGED: "HOST_THEME_CHANGED",
  HOST_LOCALE_CHANGED: "HOST_LOCALE_CHANGED",
};

export const eventsToDispatch = {};

const dispatchEvent = (eventName: string, data: any) =>
  window.dispatchEvent(new CustomEvent(eventName, { detail: data }));

export default dispatchEvent;
