import { CorpExperimentsMap } from "../types";
import { useExperimentsById } from "./useExperimentsById";

/**
 * Check to see if a variant is enabled
 *
 * @param id Experiment ID coming from Launch Darkly
 * @param variantName Variant name we're wanting to compare to
 * @returns
 */
export const useExperimentIsVariant = <
  ExperimentId extends keyof CorpExperimentsMap,
  Variant extends CorpExperimentsMap[ExperimentId]["variant"]
>(
  id: ExperimentId,
  variant: Variant
) => {
  const experiment = useExperimentsById(id);

  return experiment?.variant === variant;
};
