import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import H from "history";
import {
  B2B_PORTAL_AUTH_REDIRECT_TO,
  B2B_PORTAL_UNAUTHORIZED_PATH,
  SessionInfo,
  LoginResult,
} from "redmond";
import queryStringParser from "query-string";
import {
  isValidBusinessTravelURL,
  isCorpTenant,
  onboardingPage,
} from "@capone/common";

import { fetchLogin, postLogin, postLoginCode } from "../../api/v1/auth";
import {
  PATH_B2B_HOME,
  FIRST_TIME_USER_EXPERIENCE_SESSION,
} from "../../utils/paths";
import { LoadingScreen } from "../LoadingScreen";
import { createResponseErrorLog } from "../Logger";
import { config } from "../../api/config";
import { getPathFromURL } from "@capone/common/src/utils/getPathFromURL";

interface ILoadingPageProps {
  language: string;
  history: H.History;
}

export const LoadingPage = (props: ILoadingPageProps) => {
  const { language, history } = props;

  const redirectToSessionStorageUrl = (url: string) => {
    if (isCorpTenant(config.TENANT)) {
      // Need to `location.replace` because we potentially change React app
      window.location.replace(getPathFromURL(url));
    } else {
      history.push(getPathFromURL(url));
    }

    sessionStorage.removeItem(B2B_PORTAL_AUTH_REDIRECT_TO);
  };

  /*
    The sessionRedirectTo params can be set by the BFF if a user has only access to the
    business portal. In that case, we will redirect them automatically there.
   */
  const handleRedirect = (
    isFirstSession: boolean,
    sessionRedirectTo?: string
  ) => {
    const redirectTo = sessionStorage.getItem(B2B_PORTAL_AUTH_REDIRECT_TO);
    if (
      sessionStorage.getItem("onboarding") === "true" ||
      redirectTo?.includes("onboarding")
    ) {
      window.location.replace(onboardingPage);
    } else if (
      sessionRedirectTo &&
      !isCorpTenant(config.TENANT) &&
      isValidBusinessTravelURL(sessionRedirectTo)
    ) {
      window.location.replace(sessionRedirectTo);
    } else if (isFirstSession) {
      history.replace(FIRST_TIME_USER_EXPERIENCE_SESSION);
    } else {
      if (redirectTo) {
        redirectToSessionStorageUrl(redirectTo);
      } else {
        history.replace(PATH_B2B_HOME);
      }
    }
  };

  const hasLoginSucceeded = (login: LoginResult): login is SessionInfo => {
    if ((login as SessionInfo)?.csrfToken) {
      return true;
    }
    return false;
  };

  const handleLoginResponse = (response: LoginResult) => {
    if (hasLoginSucceeded(response)) {
      handleRedirect(response.isFirstSession, response.redirectTo);
    }
  };

  const handleLoginErrorRedirect = (error: any) => {
    if (error) {
      createResponseErrorLog(error);
      console.log(
        `DEBUG: redirecting to ${B2B_PORTAL_UNAUTHORIZED_PATH}: ${error}`
      );
      history.replace(B2B_PORTAL_UNAUTHORIZED_PATH);
    }
  };

  const JwtAuthFlow = async (token: string) => {
    try {
      await fetchLogin();
      const response = await postLogin({ token });
      handleLoginResponse(response);
    } catch (err) {
      handleLoginErrorRedirect(err);
    }
  };

  const AuthZCodeFlow = async (code: string) => {
    try {
      const response = await postLoginCode({ code });
      handleLoginResponse(response);
    } catch (err) {
      handleLoginErrorRedirect(err);
    }
  };

  useEffect(() => {
    const queryString = queryStringParser.parse(props.history.location.search);

    if (queryString.jwt) JwtAuthFlow(queryString.jwt as string);
    else if (queryString.code) AuthZCodeFlow(queryString.code as string);
    else history.replace(B2B_PORTAL_UNAUTHORIZED_PATH);
  }, []);

  return (
    <Box className="loading-page-container">
      <LoadingScreen language={language} />
    </Box>
  );
};
