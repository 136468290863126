export const HEADER_ONE = (firstName: string) =>
  `Welcome ${firstName}, discover your new way to book travel.`;
export const HEADER_TWO = "We help you get the best price on your trip.";
export const HEADER_THREE =
  "We watch flight prices and let you know when to book.";

export const STEP_ONE_BULLET_ONE =
  "Book flights, hotels and rental cars in one place.";
export const STEP_ONE_BULLET_TWO_GENERAL =
  "Earn rewards on every travel purchase.";
export const STEP_ONE_BULLET_TWO_VARIANT_ONE = (
  rewardsCurrency: string,
  cardName: string
) =>
  `Earn 5x ${rewardsCurrency} on flights and 10x on hotels when you book with your ${cardName} card.`;
export const STEP_ONE_BULLET_TWO_VARIANT_TWO = (
  rewardsCurrency: string,
  cardName: string
) =>
  `Earn 5x ${rewardsCurrency} on hotels and rental cars when you book with your ${cardName} card.`;
export const STEP_ONE_BULLET_THREE =
  "Save an average of 15% on your next trip with our price prediction tool.";

export const STEP_TWO_BULLET_ONE =
  "Get our price match guarantee if you find a better rate.";
export const STEP_TWO_BULLET_TWO =
  "Set alerts to know when flight prices drop.";
export const STEP_TWO_BULLET_THREE =
  "Book confidently with free price drop protection.";

export const STEP_THREE_SUBHEADING =
  "Check out our price prediction tool when you search for your flight.";

export const CONTINUE = "Continue";
export const CURRENT_STEP_TEXT = (step: number) => `Step ${step} of 3`;
export const SKIP_TO_FLIGHT = "Skip to flight search";
export const BEGIN_EXPLORING = "Begin Exploring";
