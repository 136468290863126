import { useEffect, useState } from "react";
import { PolicyTier } from "redmond";

export const useIsAutoApprovalEnabled = (
  policies: PolicyTier | undefined
): boolean => {
  const [isAutoApprovalEnabled, setIsAutoApprovalEnabled] = useState(false);

  useEffect(() => {
    const isAutoApprovalOn = Boolean(
      policies?.settings && !policies.settings.isApprovalRequired
    );

    setIsAutoApprovalEnabled(isAutoApprovalOn);
  }, [policies]);

  return isAutoApprovalEnabled;
};
