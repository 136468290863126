import {
  FiatPrice,
  FlightPolicy,
  PolicyTier,
  Segment,
  Slice,
  TripSegment,
  TripSlice,
} from "redmond";

const getFlightPolicies = (policies: PolicyTier["flights"]) => {
  const { shortPolicy, longPolicy } =
    policies.policies.reduce(
      (flightPolicies, policy) => {
        if (policy.duration === "Short") {
          return {
            ...flightPolicies,
            shortPolicy: policy,
          };
        }
        return { ...flightPolicies, longPolicy: policy };
      },
      { shortPolicy: {} as FlightPolicy, longPolicy: {} as FlightPolicy }
    ) ?? {};

  return { shortPolicy, longPolicy };
};

export const getStopoverDurationForSegments = (
  segments: TripSegment[] | Segment[]
) =>
  // we have to cast segments as one of the types to get around tslint error TS2349
  (segments as TripSegment[]).reduce((totalStopoverTime, segment) => {
    return totalStopoverTime + (segment.stopoverDurationMinutes ?? 0);
  }, 0);

export const getFlightPolicyLimit = (
  policies: PolicyTier["flights"] | undefined,
  durationInMinutes: number,
  isRoundTrip: boolean
): FiatPrice | null | undefined => {
  if (!policies) {
    return undefined;
  }

  const isLongFlightPolicyEnabled = policies.enableLongFlights;

  const { shortPolicy, longPolicy } = getFlightPolicies(policies);

  const isLongFlight =
    Boolean(isLongFlightPolicyEnabled) &&
    durationInMinutes / 60 > (policies.minHoursForLongFlight ?? 0);

  const flightRowPolicy = isLongFlight ? longPolicy : shortPolicy;

  const policyLimit = isRoundTrip
    ? flightRowPolicy.maxPriceForRoundTrip
    : flightRowPolicy.maxPriceForOneWay;

  return policyLimit;
};

export const getMultiCityPolicyLimit = (
  policies: PolicyTier["flights"] | undefined,
  slices: TripSlice[] | Slice[]
): FiatPrice | null | undefined => {
  if (!policies) {
    return undefined;
  }

  const isLongFlightPolicyEnabled = policies.enableLongFlights;
  const { shortPolicy, longPolicy } = getFlightPolicies(policies);

  const calculateTotalPrice = (policy: FlightPolicy, count: number) => {
    return policy.maxPriceForOneWay
      ? {
          ...policy.maxPriceForOneWay,
          value: policy.maxPriceForOneWay.value * count,
        }
      : null;
  };

  if (!isLongFlightPolicyEnabled) {
    return calculateTotalPrice(shortPolicy, slices.length);
  }

  const slicesArray = slices.map((slice) => {
    if ("segments" in slice) {
      return {
        totalDurationMinutes: slice.totalDurationMinutes,
        segments: slice.segments,
      };
    }
    return {
      totalDurationMinutes: slice.totalDurationMinutes,
      segments: slice.segmentDetails,
    };
  });

  const { shortSliceCount, longSliceCount } = slicesArray.reduce(
    (sliceCounts, slice) => {
      const stopoverInMinutes = getStopoverDurationForSegments(
        slice.segments as TripSegment[]
      );

      const durationInMinutes = slice.totalDurationMinutes ?? 0;
      const isLongFlight =
        (durationInMinutes - stopoverInMinutes) / 60 >
        (policies.minHoursForLongFlight ?? 0);
      return {
        shortSliceCount: sliceCounts.shortSliceCount + (isLongFlight ? 0 : 1),
        longSliceCount: sliceCounts.longSliceCount + (isLongFlight ? 1 : 0),
      };
    },
    { shortSliceCount: 0, longSliceCount: 0 }
  );

  const totalSliceCount = longSliceCount + shortSliceCount;
  // The trip details used to determine flight duration is arbitrarily selected as the first one in the fares list,
  // which may not always match the policy compliance shown on the UI. To address this, we can assume that rendering
  // a policy descriptor indicates OOP trip, which means the trip duration must be within a defined range and not set to "no maximum".
  if (!shortPolicy.maxPriceForOneWay && longPolicy.maxPriceForOneWay) {
    return calculateTotalPrice(longPolicy, totalSliceCount);
  } else if (!longPolicy.maxPriceForOneWay && shortPolicy.maxPriceForOneWay) {
    return calculateTotalPrice(shortPolicy, totalSliceCount);
  } else if (shortPolicy.maxPriceForOneWay && longPolicy.maxPriceForOneWay) {
    const totalPrice =
      shortPolicy.maxPriceForOneWay.value * shortSliceCount +
      longPolicy.maxPriceForOneWay.value * longSliceCount;
    return { ...shortPolicy.maxPriceForOneWay, value: totalPrice };
  }

  return undefined;
};
